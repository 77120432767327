import { ErrorMessage as NSErrorMessage } from '@hookform/error-message';

const errorMessage = ({ message }) => (
  <p style={{ color: 'red', fontSize: '14px', marginTop: '-16px' }}>
    {message}
  </p>
);

const ErrorMessage = ({ errors, name }) => {
  return <NSErrorMessage errors={errors} name={name} render={errorMessage} />;
};

export default ErrorMessage;
