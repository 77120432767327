import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import ArsatLogo from 'src/components/pages/login/ArsatLogo';
import styled from '@emotion/styled';

const Footer = () => {
  const theme = useTheme();

  const StyledBox = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: '#ebebeb',
    display: 'flex',
    height: '75px',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fff',
      borderRadius: 0,
      bottom: 0,
      boxShadow: 'none',
      paddingRight: theme.spacing(2.5),
      paddingTop: 'calc(100vh - 400px)',
      width: '100vw',
    },
  }));

  const Text = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[900],
    fontFamily: theme.typography.secondary,
    fontSize: 14,
    fontWeight: 400,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fff',
      margin: theme.spacing(0, 1.25, 0, 0),
    },
  }));

  return (
    <StyledBox>
      <Text>Un servicio de</Text>
      <Box sx={{ paddingTop: '4px' }}>
        <ArsatLogo color={theme.palette.grey[900]} size={'small'} />
      </Box>
    </StyledBox>
  );
};

export default Footer;
