import Image from 'next/image';
import { iconSize } from 'src/utils/Constants';

export const icon = {
  avatar: { src: '/icons/avatar.svg', alt: 'Avatar', width: 34, height: 34 },
  work: {
    src: '/icons/work.svg',
    alt: 'Organizaciones',
    width: iconSize.sidebar,
    height: iconSize.sidebar,
  },
  device: {
    src: '/icons/device.svg',
    alt: 'Dispositivos',
    width: iconSize.sidebar,
    height: iconSize.sidebar,
  },
  menu: {
    src: '/icons/menu.svg',
    alt: 'Menu',
    width: iconSize.sidebar,
    height: iconSize.sidebar,
  },
  profile: {
    src: '/icons/profile.svg',
    alt: 'Usuarios',
    width: iconSize.sidebar,
    height: iconSize.sidebar,
  },
  block: {
    src: '/icons/block.svg',
    alt: 'Bloquear',
    width: iconSize.tables,
    height: iconSize.tables,
  },
  edit: {
    src: '/icons/edit.svg',
    alt: 'Editar',
    width: iconSize.tables,
    height: iconSize.tables,
  },
  login: {
    src: '/icons/login.svg',
    alt: 'Login',
    width: iconSize.tables,
    height: iconSize.tables,
  },
  search: {
    src: '/icons/search.svg',
    alt: 'Buscar',
    width: iconSize.main,
    height: iconSize.main,
  },
  menu2: {
    src: '/icons/menu2.svg',
    alt: 'Menu',
    width: iconSize.main,
    height: iconSize.main,
  },
  location: {
    src: '/icons/location.svg',
    alt: 'Ubicacion',
    width: iconSize.tables,
    height: iconSize.tables,
  },
  location_grey: {
    src: '/icons/location-grey.svg',
    alt: 'Ubicacion',
    width: iconSize.tables,
    height: iconSize.tables,
  },
  delete: {
    src: '/icons/delete.svg',
    alt: 'Eliminar',
    width: iconSize.tables,
    height: iconSize.tables,
  },
  watch: {
    src: '/icons/watch.svg',
    alt: 'Ver más',
    width: iconSize.tables,
    height: iconSize.tables,
  },
  alarm_square: {
    src: '/icons/alarm-square.svg',
    alt: 'Alerta',
    width: iconSize.accordion,
    height: iconSize.accordion,
  },
  moving_square: {
    src: '/icons/moving-square.svg',
    alt: 'Dirección',
    width: iconSize.accordion,
    height: iconSize.accordion,
  },
  disconnected_square: {
    src: '/icons/disconnected-square.svg',
    alt: 'Desconectado',
    width: iconSize.accordion,
    height: iconSize.accordion,
  },
  stopped_square: {
    src: '/icons/stopped-square.svg',
    alt: 'Detenido',
    width: iconSize.accordion,
    height: iconSize.accordion,
  },
  download: {
    src: '/icons/download.svg',
    alt: 'Descargar',
    width: iconSize.accordion,
    height: iconSize.accordion,
  },
  logo: {
    src: '/icons/logo.svg',
    alt: 'Logo',
    width: iconSize.logo,
    height: iconSize.logo,
  },
  link_off: {
    src: '/icons/link-off.svg',
    alt: 'Desvincular dispositivo',
    width: iconSize.input,
    height: iconSize.input,
  },
  dropdown_arrow_blue: {
    src: '/icons/dropdown-arrow-blue.svg',
    alt: 'Mostrar acciones',
    width: iconSize.small_icon,
    height: iconSize.small_icon,
  },
  warning_yellow: {
    src: '/icons/warning-yellow.svg',
    alt: 'Advertencia',
    width: iconSize.medium_icon,
    height: iconSize.medium_icon,
  },
  file_arrow_up: {
    src: '/icons/file-arrow-up.svg',
    alt: 'Subir archivo',
    width: iconSize.big_icon,
    height: iconSize.big_icon,
  },
  history: {
    src: '/icons/history.svg',
    alt: 'Histórico',
    width: iconSize.tables,
    height: iconSize.tables,
  },
};

export const ImageIcon = (props) => {
  return (
    <Image
      src={icon[props.icon].src}
      alt={icon[props.icon].alt}
      width={icon[props.icon].width}
      height={icon[props.icon].height}
    />
  );
};
