import * as S from 'src/components/pages/login/Login.styled.js';
import Header from 'src/components/pages/login/Header';
import Footer from 'src/components/pages/login/Footer';
import { ImageIcon } from 'src/public';

const LoginLayout = ({ children }) => {
  return (
    <S.PageWrapper>
      <Header />
      <S.Main>
        <S.Form>
          <S.TitleWrapper>
            <S.ImageWrapper>
              <ImageIcon icon={'logo'} />
            </S.ImageWrapper>
            <S.Title>Siguiendo Activos</S.Title>
          </S.TitleWrapper>
          {children}
        </S.Form>
      </S.Main>
      <Footer />
    </S.PageWrapper>
  );
};

export default LoginLayout;
