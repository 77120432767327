import { BORoles, localStorageKeys, TABLE_COLUMNS } from 'src/utils/Constants';

const isSuperAdmin = (userData) => {
  return userData?.roles?.includes(BORoles.SUPER_ADMIN);
};

export const canEditUser = (
  currentUserData,
  userData,
  canModifyHimself,
  canEditSuperAdmin
) => {
  if (canModifyHimself && canEditSuperAdmin) {
    return true;
  } else if (!canModifyHimself && canEditSuperAdmin) {
    return (
      currentUserData?.id_user && currentUserData?.id_user !== userData.id_user
    );
  } else if (canModifyHimself && !canEditSuperAdmin) {
    return (
      currentUserData?.roles?.includes(BORoles.ADMIN_MANAGER) &&
      !isSuperAdmin(userData)
    );
  } else {
    return (
      currentUserData?.id_user &&
      currentUserData?.id_user !== userData.id_user &&
      !isSuperAdmin(userData)
    );
  }
};

export const getUserDataFromLocalStorage = () => {
  const storage = window?.localStorage;
  const { AUTH_USER } = localStorageKeys;
  const userData = JSON.parse(storage.getItem(AUTH_USER));
  return userData;
};

export const getUserFilters = (params) => {
  let filters = params.userName
    ? 'username_filter=' + encodeURIComponent(params.userName.trim())
    : '';
  filters += params.pageSize ? '&page_size=' + params.pageSize : '';
  filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';
  filters += params.id_user ? '&id_user=' + params.id_user : '';
  filters += params.name ? '&name=' + params.name : '';
  if (params?.states?.length === 1) {
    filters += `&active=${!!(params.states[0] === 'Activo')}`;
  }
  if (params?.organizations?.length > 0) {
    for (let organization of params.organizations) {
      filters += `&company=${organization}`;
    }
  }
  if (params?.roles?.length > 0) {
    for (let role of params.roles) {
      filters += `&roles=${
        role === 'Super Administrador'
          ? 'super_admin'
          : role === 'Administrador'
          ? 'manager'
          : role === 'Responsable de Flota'
          ? 'fleet_manager'
          : 'regular'
      }`;
    }
  }
  return filters;
};

export const getDeviceFilters = (params) => {
  let filters = '';
  filters += params.pageSize ? '&page_size=' + params.pageSize : '';
  filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';
  filters += params.id_device ? '&id_device=' + params.id_device : '';
  filters += params.q ? '&q=' + params.q : '';
  if (params?.organizations?.length > 0) {
    for (let organization of params.organizations) {
      filters += `&company=${organization}`;
    }
  }
  if (params?.deviceModels?.length > 0) {
    for (let deviceModel of params.deviceModels) {
      filters += `&version=${deviceModel}`;
    }
  }
  return filters;
};

export const getAssetFilters = (params) => {
  let filters = 'sort_order=-1&sort_field=id_asset';
  filters += params.pageSize ? '&page_size=' + params.pageSize : '';
  filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';
  filters += params.id_asset ? '&id_asset=' + params.id_asset : '';
  filters += params.q ? '&q=' + params.q : '';
  if (params?.states?.length === 1) {
    filters += `&active=${!!(params.states[0] === 'Activo')}`;
  }
  if (params?.tags?.length > 0) {
    for (let tag of params.tags) {
      filters += `&tags=${tag}`;
    }
  }
  if (params?.fleets?.length > 0) {
    for (let fleet of params.fleets) {
      filters += `&fleet=${fleet}`;
    }
  }
  return filters;
};

export const getFleetFilters = (params) => {
  let filters = 'sort_order=-1&sort_field=id_fleet';
  filters += params.pageSize ? '&page_size=' + params.pageSize : '';
  filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';
  filters += params.id_fleet ? '&id_fleet=' + params.id_fleet : '';
  filters += params.q ? '&q=' + params.q : '';
  return filters;
};

export const getNoveltiesFilters = (params) => {
  const {
    q,
    id_asset,
    date_start,
    date_end,
    statesSelected,
    fleetsSelected,
    tagsSelected,
    asset_active,
  } = params;
  let filters = '';
  filters += q ? '&q=' + q : '';
  filters += id_asset ? '&id_asset=' + id_asset : '';
  filters += date_start ? '&date_start=' + encodeURIComponent(date_start) : '';
  filters += date_end ? '&date_end=' + encodeURIComponent(date_end) : '';
  if (typeof asset_active === 'boolean') {
    filters += asset_active ? '&asset_active=true' : '&asset_active=false';
  }
  if (params?.statesSelected?.length > 0) {
    for (let stateSelected of statesSelected) {
      filters += '&state=' + stateSelected;
    }
  }
  if (params?.fleetsSelected?.length > 0) {
    for (let fleetSelected of fleetsSelected) {
      filters += '&fleet_name=' + fleetSelected;
    }
  }
  if (params?.tagsSelected?.length > 0) {
    for (let tagSelected of tagsSelected) {
      filters += '&tags=' + tagSelected;
    }
  }
  return filters?.substring(1);
};

export const getNoveltiesHistoricFilters = (params) => {
  const { id_asset, date_start, date_end } = params;
  let filters = '';
  filters += id_asset ? 'id_asset=' + id_asset : '';
  filters += date_start ? '&date_start=' + encodeURIComponent(date_start) : '';
  filters += date_end ? '&date_end=' + encodeURIComponent(date_end) : '';
  return filters;
};

export const getExportFilters = (params) => {
  const { id_asset, date_start, date_end } = params;
  let filters = '';
  filters += id_asset ? 'id_asset=' + id_asset : '';
  filters += date_start ? '&date_start=' + encodeURIComponent(date_start) : '';
  filters += date_end ? '&date_end=' + encodeURIComponent(date_end) : '';
  return filters;
};

export const getAssetState = (asset, historic, index) => {
  if (historic) {
    let assetStatus = asset?.asset_status;
    if (assetStatus === 'moving') assetStatus += `_${asset?.direction}`;

    if (index === 0) return assetStatus;
    if (assetStatus.indexOf('moving') !== -1) {
      assetStatus = assetStatus.replace('moving', 'green_arrow');
      return assetStatus;
    }
    if (assetStatus.indexOf('stopped') !== -1) return 'stopped_small';

    return assetStatus;
  }

  let assetStatus = asset?.asset_status;
  if (assetStatus === 'moving') assetStatus += `_${asset?.direction}`;

  return asset?.disconnected ? 'disconnected' : assetStatus;
};

export const getFleetOptions = (assets) => {
  if (!assets) return null;
  const fleetOptions = [];
  for (let asset of assets) {
    for (let fleet of asset.fleet_list) {
      if (fleet?.name) {
        fleetOptions.push(fleet?.name);
      }
    }
  }
  const fleetUniqueOptions = new Set(fleetOptions);
  return Array.from(fleetUniqueOptions);
};

export const getTagOptions = (assets) => {
  if (!assets) return null;
  const tagOptions = [];
  for (let asset of assets) {
    for (let tag of asset.tags) {
      if (tag?.tag) {
        tagOptions.push(tag.tag);
      }
    }
  }
  const tagUniqueOptions = new Set(tagOptions);
  return Array.from(tagUniqueOptions);
};

export const refetchOnTime = (refetch) => {
  return setTimeout(function () {
    refetch();
  }, 0);
};

export const downloadFile = (dataBlob, customName, type) => {
  const fileName = makeFilename(customName, type);
  if (['csv', 'xlsx'].includes(type))
    fileAndLinkDownload(dataBlob, fileName, 'application/vnd.ms-excel');
  if (type === 'kmz')
    fileAndLinkDownload(dataBlob, fileName, 'application/vnd.google-earth.kmz');
};

export const makeFilename = (name, type) => {
  const dateNow = new Date().toLocaleString();
  const appName = process.env.NEXT_PUBLIC_APP_WEBSITE_NAME;
  const fileName = `${name}_${dateNow}_${appName}.${type}`;

  return fileName;
};

export const fileAndLinkDownload = (data, fileName, type) => {
  const blobData = new File([data], fileName, { type: type });
  const xmlUrl = window.URL.createObjectURL(blobData);
  const tempLink = document.createElement('a');
  tempLink.href = xmlUrl;
  tempLink.setAttribute('download', fileName);
  tempLink.click();
};

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date?.getTime());
};

export const isSameDate = (date1, date2) => {
  return (
    date1?.getYear() === date2?.getYear() &&
    date1?.getMonth() === date2?.getMonth() &&
    date1?.getDate() === date2?.getDate()
  );
};

export const associatedDeviceTitle = (assetId) => {
  return `El dispositivo está asociado ${
    assetId ? `al activo ${assetId}` : 'a un activo'
  }. Para desactivarlo edite el activo y desvincule el dispositivo.`;
};

export const getNonFleetManagerRole = (roles) => {
  return roles?.filter((role) => role !== 'fleet_manager')?.[0];
};

export const canRenderDeviceColumn = (
  title,
  showFullList = false,
  canEditDevice = false
) => {
  if (!showFullList && title === TABLE_COLUMNS.DEVICES.COMPANY) {
    return false;
  } else if (!canEditDevice && title === TABLE_COLUMNS.DEVICES.EDIT) {
    return false;
  } else {
    return true;
  }
};
