import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useQueryClient } from 'react-query';
import ReCAPTCHA from 'react-google-recaptcha';

import { useRouter } from 'next/router';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AuthService from 'src/services/AuthService';
import UserService from 'src/services/UserService';
import ErrorMessage from 'src/components/ErrorMessage';
import LoginLayout from 'src/components/pages/login/LoginLayout';
import { Feature } from 'src/context/Features/FeaturesContext';
import { LOGIN_ERRORS } from 'src/utils/Constants';
import { routeLayout } from 'src/paths';
import { updateAbility } from 'src/context/ability/Ability';
import { useSnackBars } from 'src/context/Snackbar/SnackBarContext';
import * as S from 'src/components/pages/login/Login.styled.js';

export default function Login() {
  const queryClient = useQueryClient();
  const router = useRouter();
  const [isTokenSaved, setIsTokenSaved] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const reRef = useRef();
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const { showErrorSnackbar } = useSnackBars();

  useEffect(() => {
    queryClient.removeQueries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (errors?.length > 0) console.error(errors);

  const onSubmit = async (values) => {
    reRef.current.reset();
    const token = await reRef.current.executeAsync();
    const credentials = buildRequestForm(values, token);
    logIn(credentials);
  };

  const buildRequestForm = (formValues, token) => {
    const requestValues = {
      username: formValues.User,
      password: formValues.Password,
      captcha: token,
    };
    return requestValues;
  };

  const { mutate: logIn } = useMutation(AuthService.login, {
    onSuccess: onLoginSuccess,
    onError: onLoginError,
  });

  function onLoginSuccess(response) {
    setIsTokenSaved(AuthService.saveTokenInLocalStorage(response));
  }

  function onLoginError(error) {
    const errorText = LOGIN_ERRORS[error];
    showErrorSnackbar(errorText);
  }

  useQuery(['currentUser', isTokenSaved], UserService.getCurrentUser, {
    enabled: isTokenSaved,
    onSuccess: onGetCurrentUserSuccess,
    onError: onGetCurrentUserError,
  });

  function onGetCurrentUserSuccess(response) {
    UserService.saveCurrentUserInLocalStorage(response);
    updateAbility();
    const idcompany = AuthService.getCompanyIdFromToken(
      AuthService.getAccessToken()
    );
    redirectToPageLogged(idcompany);
  }

  const redirectToPageLogged = (idcompany) => {
    const urlForRedirect = AuthService.isSuperAdmin()
      ? routeLayout.admin(idcompany)
      : routeLayout.application(idcompany);
    router.replace(urlForRedirect.as);
  };

  function onGetCurrentUserError(error) {
    console.error('Get Current User Error: ', error);
  }

  return (
    <>
      <ReCAPTCHA
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
        size="invisible"
        badge="bottomleft"
        ref={reRef}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.InputArea>
          <S.Input
            {...register('User', { required: 'Debe ingresar su usuario.' })}
            placeholder="Usuario"
            type="text"
          />
          <ErrorMessage errors={errors} name="User" />
          <S.Input
            {...register('Password', {
              required: 'Debe ingresar su contraseña.',
            })}
            placeholder="Contraseña"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={toggleShowPassword}
                  aria-label={'Eye icon'}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <ErrorMessage errors={errors} name="Password" />
        </S.InputArea>
        <S.SubmitArea>
          <Feature name="RESET_PASSWORD">
            <S.Link to={'/'}>¿Olvidaste tu contraseña?</S.Link>
          </Feature>
          <S.Button type="submit" aria-label={'Submit'}>
            Iniciar sesión
          </S.Button>
        </S.SubmitArea>
      </form>
    </>
  );
}

Login.getLayout = function getLayout(page) {
  return <LoginLayout>{page}</LoginLayout>;
};
