import PropTypes from 'prop-types';
const xmlns = 'http://www.w3.org/2000/svg';

const ArsatLogo = ({ color, size, adjust, spacing, s1, s2, s3, s4 }) => (
  <>
    {size === 'big' ? (
      <>
        <div style={{ display: 'inline-block', marginRight: s1 || spacing }}>
          <svg
            width={43 * adjust}
            height={35 * adjust}
            viewBox="0 0 43 35"
            xmlns={xmlns}
          >
            <path
              d="M15.9289 0L0 35H10.2014L21.0335 11.1992L31.8696 35H42.0947L26.15 0H15.9289Z"
              fill={color}
            />
          </svg>
        </div>
        <div style={{ display: 'inline-block', marginRight: s2 || spacing }}>
          <svg
            width={39 * adjust}
            height={35 * adjust}
            viewBox="0 0 39 35"
            xmlns={xmlns}
          >
            <path
              d="M27.2114 21.2096C29.9275 21.1554 32.5144 20.0143 34.4165 18.0315C36.3186 16.0486 37.3841 13.3821 37.3841 10.6048C37.3841 7.82749 36.3186 5.16098 34.4165 3.1781C32.5144 1.19521 29.9275 0.0541325 27.2114 0H0.0444031V7.40702H25.4376C26.274 7.40702 27.0761 7.74669 27.6674 8.3513C28.2588 8.9559 28.5911 9.77593 28.5911 10.631C28.5911 11.486 28.2588 12.306 27.6674 12.9107C27.0761 13.5153 26.274 13.8549 25.4376 13.8549H0.0444031V34.9919H9.54025V21.2096H11.117L26.6004 35H38.0909L22.5364 21.2096H27.2114Z"
              fill={color}
            />
          </svg>
        </div>
        <div style={{ display: 'inline-block', marginRight: s3 || spacing }}>
          <svg
            width={41 * adjust}
            height={35 * adjust}
            viewBox="0 0 41 35"
            xmlns={xmlns}
          >
            <path
              d="M29.8251 13.863H12.8753C12.0389 13.863 11.2368 13.5233 10.6454 12.9187C10.054 12.3141 9.72181 11.4941 9.72181 10.639C9.72181 9.78399 10.054 8.96396 10.6454 8.35936C11.2368 7.75475 12.0389 7.41508 12.8753 7.41508H38.1502V0H11.0817C8.36569 0.0541325 5.77875 1.19521 3.87666 3.1781C1.97457 5.16098 0.909088 7.82749 0.909088 10.6048C0.909088 13.3821 1.97457 16.0486 3.87666 18.0315C5.77875 20.0143 8.36569 21.1554 11.0817 21.2096H28.0315C28.871 21.2096 29.6761 21.5505 30.2697 22.1574C30.8633 22.7642 31.1968 23.5873 31.1968 24.4456C31.1968 25.3038 30.8633 26.1269 30.2697 26.7338C29.6761 27.3407 28.871 27.6816 28.0315 27.6816H2.87093V35H29.7817C31.1561 35.0302 32.5225 34.78 33.8011 34.2638C35.0798 33.7477 36.2449 32.9761 37.2283 31.9941C38.2118 31.0121 38.9938 29.8395 39.5287 28.5449C40.0636 27.2502 40.3406 25.8595 40.3435 24.4541C40.3463 23.0487 40.0751 21.6568 39.5455 20.3599C39.0159 19.0629 38.2387 17.887 37.2593 16.9008C36.2799 15.9146 35.118 15.138 33.8415 14.6164C32.565 14.0948 31.1995 13.8386 29.8251 13.863Z"
              fill={color}
            />
          </svg>
        </div>
        <div style={{ display: 'inline-block', marginRight: s4 || spacing }}>
          <svg
            width={43 * adjust}
            height={35 * adjust}
            viewBox="0 0 43 35"
            xmlns={xmlns}
          >
            <path
              d="M15.9289 0L0 35H10.2014L21.0335 11.1992L31.8696 35H42.0947L26.15 0H15.9289Z"
              fill={color}
            />
          </svg>
        </div>
        <div style={{ display: 'inline-block' }}>
          <svg
            width={30 * adjust}
            height={35 * adjust}
            viewBox="0 0 30 35"
            xmlns={xmlns}
          >
            <path
              d="M0.586151 0V7.41105H10.5116V35H20.0114V7.41105H30V0H0.586151Z"
              fill={color}
            />
          </svg>
        </div>
      </>
    ) : size === 'small' ? (
      <>
        <div style={{ display: 'inline-block', marginRight: s1 || spacing }}>
          <svg
            width={24 * adjust}
            height={19 * adjust}
            viewBox="0 0 24 19"
            xmlns={xmlns}
          >
            <path
              d="M8.76089 0L0 19H5.61079L11.5685 6.07956L17.5283 19H23.1521L14.3825 0H8.76089Z"
              fill={color}
            />
          </svg>
        </div>
        <div style={{ display: 'inline-block', marginRight: s2 || spacing }}>
          <svg
            width={22 * adjust}
            height={19 * adjust}
            viewBox="0 0 22 19"
            xmlns={xmlns}
          >
            <path
              d="M15.2663 11.5138C16.7601 11.4844 18.1829 10.8649 19.2291 9.78851C20.2752 8.71208 20.8612 7.26455 20.8612 5.75688C20.8612 4.24921 20.2752 2.80168 19.2291 1.72525C18.1829 0.648827 16.7601 0.0293862 15.2663 0H0.324402V4.02096H14.2907C14.7507 4.02096 15.1918 4.20535 15.5171 4.53356C15.8423 4.86178 16.0251 5.30693 16.0251 5.7711C16.0251 6.23527 15.8423 6.68042 15.5171 7.00864C15.1918 7.33685 14.7507 7.52124 14.2907 7.52124H0.324402V18.9956H5.54712V11.5138H6.41432L14.9302 19H21.2499L12.695 11.5138H15.2663Z"
              fill={color}
            />
          </svg>
        </div>
        <div style={{ display: 'inline-block', marginRight: s3 || spacing }}>
          <svg
            width={22 * adjust}
            height={19 * adjust}
            viewBox="0 0 22 19"
            xmlns={xmlns}
          >
            <path
              d="M16.0899 7.52562H6.76749C6.3075 7.52562 5.86635 7.34123 5.54108 7.01301C5.21582 6.6848 5.03309 6.23964 5.03309 5.77547C5.03309 5.31131 5.21582 4.86615 5.54108 4.53794C5.86635 4.20972 6.3075 4.02533 6.76749 4.02533H20.6687V0H5.78105C4.28723 0.0293862 2.86441 0.648827 1.81826 1.72525C0.772114 2.80168 0.186096 4.24921 0.186096 5.75688C0.186096 7.26455 0.772114 8.71208 1.81826 9.78851C2.86441 10.8649 4.28723 11.4844 5.78105 11.5138H15.1035C15.5652 11.5138 16.008 11.6988 16.3345 12.0283C16.6609 12.3577 16.8444 12.8046 16.8444 13.2705C16.8444 13.7364 16.6609 14.1832 16.3345 14.5126C16.008 14.8421 15.5652 15.0272 15.1035 15.0272H1.26511V19H16.066C16.8219 19.0164 17.5735 18.8805 18.2767 18.6004C18.98 18.3202 19.6208 17.9013 20.1617 17.3682C20.7026 16.8352 21.1327 16.1986 21.4269 15.4958C21.7211 14.793 21.8734 14.038 21.875 13.2751C21.8766 12.5121 21.7274 11.7565 21.4361 11.0525C21.1449 10.3484 20.7174 9.71006 20.1787 9.1747C19.64 8.63935 19.001 8.21776 18.2989 7.9346C17.5968 7.65145 16.8458 7.51241 16.0899 7.52562Z"
              fill={color}
            />
          </svg>
        </div>
        <div style={{ display: 'inline-block', marginRight: s4 || spacing }}>
          <svg
            width={24 * adjust}
            height={19 * adjust}
            viewBox="0 0 24 19"
            xmlns={xmlns}
          >
            <path
              d="M8.76089 0L0 19H5.61079L11.5685 6.07956L17.5283 19H23.1521L14.3825 0H8.76089Z"
              fill={color}
            />
          </svg>
        </div>
        <div style={{ display: 'inline-block' }}>
          <svg
            width={17 * adjust}
            height={19 * adjust}
            viewBox="0 0 17 19"
            xmlns={xmlns}
          >
            <path
              d="M0.822388 0V4.02314H6.28141V19H11.5063V4.02314H17V0H0.822388Z"
              fill={color}
            />
          </svg>
        </div>
      </>
    ) : (
      ''
    )}
  </>
);

ArsatLogo.defaultProps = {
  color: '#0094D4',
  size: 'big',
  adjust: 1,
  spacing: 0,
  s1: 0,
  s2: 0,
  s3: 0,
  s4: 0,
};

ArsatLogo.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  adjust: PropTypes.number,
  spacing: PropTypes.number,
  s1: PropTypes.number,
  s2: PropTypes.number,
  s3: PropTypes.number,
  s4: PropTypes.number,
};

export default ArsatLogo;
