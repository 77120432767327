import AuthService from 'src/services/AuthService';
import axiosInstanceWithInterceptors from 'src/services/InterceptorsForAxiosRequests';
import { apiRoute } from 'src/paths';
import { localStorageKeys } from 'src/utils/Constants';
import { getUserFilters } from 'src/utils/Functions';

const { AUTH_USER } = localStorageKeys;

export const storage =
  typeof window !== 'undefined' ? window?.localStorage : {};

class UserService {
  //para el login
  getCurrentUser() {
    return axiosInstanceWithInterceptors.get(
      apiRoute.currentUser,
      AuthService.getAuthHeader()
    );
  }

  saveCurrentUserInLocalStorage = (userInfoResponse) => {
    const { data } = userInfoResponse;
    try {
      storage.setItem(AUTH_USER, JSON.stringify(data));
      return userInfoResponse;
    } catch (e) {
      return false;
    }
  };

  getUsers(params) {
    let filters = getUserFilters(params);
    return axiosInstanceWithInterceptors.get(
      apiRoute.users + '?' + filters,
      AuthService.getAuthHeader()
    );
  }

  addNewUser(userData) {
    return axiosInstanceWithInterceptors.post(
      apiRoute.users,
      userData,
      AuthService.getAuthHeader()
    );
  }

  editUser(user) {
    return axiosInstanceWithInterceptors.patch(
      apiRoute.users + '/' + user.id,
      user.data,
      AuthService.getAuthHeader()
    );
  }

  autocompleteUser() {
    return axiosInstanceWithInterceptors.get(
      apiRoute.users_autocomplete,
      AuthService.getAuthHeader()
    );
  }

  exportUsers(params) {
    let filters = getUserFilters(params);
    return axiosInstanceWithInterceptors.get(
      apiRoute.users_export_user_list + '?' + filters,
      AuthService.getAuthHeaderBlob()
    );
  }
}

export default new UserService();
