import { Box } from '@mui/material';
import styled from '@emotion/styled';

const Header = () => {
  const HeaderLayout = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    height: '35vh',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 4),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }));

  return <HeaderLayout />;
};

export default Header;
